<template>
  <div>
    <img src="../assets/img/bg.png" alt="" width="100%" />
    <div>
      <div class="cardOneBox">
        <div class="hotelNameBox">
          {{ showData.hotelName }}
          <div>
            <div class="hotelContentBox">
              <span class="formLable">入住：</span>
              <span class="formContnet">{{ showData.checkIn }}</span>
            </div>
            <div class="viderBox">
              <el-divider>
                <div class="viderContent">
                  <span>{{ showData.totalRoomNights }}</span> 晚
                </div>
              </el-divider>
            </div>
            <div class="hotelContentBox">
              <span class="formLable">离店：</span>
              <span class="formContnet">{{ showData.checkOut }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="cardTwoBox">
        <el-row class="hotelNameBoxTwo" style="padding-top: 20px">
          <el-col :span="6" class="formLable" style="text-align: left"
            >房型</el-col
          >
          <el-col :span="18" class="formContnet" style="text-align: right"
            >{{ showData.roomName }}《{{
              showData.mealCountAndMealType
            }}》</el-col
          >
        </el-row>
        <el-row class="hotelNameBoxTwo">
          <el-col :span="6" class="formLable" style="text-align: left"
            >房间数</el-col
          >
          <el-col :span="18" class="formContnet" style="text-align: right"
            ><span style="color: #256ef1">{{ showData.numberOfUnits }}</span>
            间</el-col
          >
        </el-row>
        <el-row class="hotelNameBoxTwo">
          <el-col :span="6" class="formLable" style="text-align: left"
            >入住人</el-col
          >
          <el-col :span="18" class="formContnet" style="text-align: right">{{
            showData.guestContact
          }}</el-col>
        </el-row>
      </div>
      <!-- style="border-right: 1px solid #eeeeee;" -->
      <div class="footerBox">
        <div class="inlin50">
          <span @click="refuseH5">拒绝</span>
        </div>
        <div class="vidersBox">
          <el-divider direction="vertical"></el-divider>
        </div>

        <div class="inlin50">
          <span @click="acceptH5"> 接受 </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderInfoToHotel_api,acceptH5_api,refuseH5_api } from "../apis/allroute";
export default {
  data() {
    return {
      hotelName: "蓝海玉华大饭店（青岛流亭机场店）",
      showData: "",
      orderId:'',
    };
  },
  created() {
    this.getShowData();
  },
  methods: {
    getShowData() {
     
      this.orderId= this.$route.query.id
      this.getOrderInfoToHotel()
    },
    async getOrderInfoToHotel() {
      let { code, data } = await getOrderInfoToHotel_api({
        orderId: this.orderId,
      });
      if (code == 0) {
        this.showData = data;
      }
    },
    //接受
    async acceptH5() {
      let { code, data } = await acceptH5_api({
        orderId: this.orderId,
      });
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
      }
    },
    //拒绝
    async refuseH5() {
        let { code, data } = await refuseH5_api({
        orderId: this.orderId,
      });
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.cardOneBox {
  //margin-left: 15px;
  margin: 0 auto;
  width: 80vw;
  //height: 130px;
  background: #ffffff;
  //background: #FF33FF;
  border-radius: 7.5px;
  position: relative;
  top: -30px;
}

.cardTwoBox {
  margin: 0 auto;
  width: 80vw;
  height: 130px;
  background: #ffffff;
  // background: #FF33FF;
  border-radius: 7.5px;
  position: relative;
  top: -30px;
}

.cardThrBox {
  margin: 0 auto;
  width: 80vw;
  height: 90px;
  background: #ffffff;
  // background: #FF33FF;
  border-radius: 7.5px;
  position: relative;
  top: -20px;
}

.hotelNameBox {
  padding: 30px 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}

.hotelNameBoxTwo {
  padding: 5px 10px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}

.formLable {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

.formContnet {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}

.hotelContentBox {
  width: 45%;
  display: inline-block;
  // vertical-align:top;
}

.viderBox {
  width: 10%;
  display: inline-block;
  // vertical-align: top;
  position: relative;
  top: 20px;

  /deep/ .el-divider__text {
    padding: 0px;
  }
}

.viderContent {
  width: 30px;
  height: 14px;
  border: 1px solid #999999;
  border-radius: 7px;
  line-height: 14px;
  text-align: center;
}

.vidersBox {
  display: inline-block;
  width: 10vw;
  text-align: center;
  height: 10vh;
  // vertical-align: top;
}
.footerBox {
  width: 100vw;
  height: 10vh;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #ffffff;
  border-radius: 10px;
}
.inlin50 {
  display: inline-block;
  width: 45vw;
  text-align: center;
  line-height: 10vh;
  font-family: PingFang SC;
  font-weight: bold;
  color: #256ef1;
  cursor: pointer;
}
</style>
